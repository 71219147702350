<template>
  <div class="main">
    <div class="top_bg">
      <div class="zf_suess">
        <ul>
          <li style="width: 40%">
            <img src="../../assets/img/suess.png" alt="" />
          </li>
          <li
            style="
              width: 60%;
              padding-left: 16px;
              box-sizing: border-box;
              padding-top: 5px;
            "
          >
            <div style="font-size: 18px; color: #fff">支付成功~</div>
            <div
              style="
                font-size: 12px;
                color: #fff;
                padding-top: 5px;
                box-sizing: border-box;
                letter-spacing: 2px;
              "
            >
              请在15分钟内离场
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="juan">
      <img src="../../assets/img/juan.png" alt="" />
      <div style="width: 85%; position: absolute; top: 0.3rem; left: 7.5%">
        <div style="font-size: 0.14rem; height: 0.3rem; line-height: 0.3rem">
          车牌号码：{{ adverInfo&&adverInfo.plateNumber?adverInfo.plateNumber:'' }}
        </div>
        <div style="font-size: 0.14rem; height: 0.3rem; line-height: 0.3rem">
          入场时间：{{ filterTime(adverInfo ? adverInfo.start_time : '') }}
        </div>
        <div style="font-size: 0.14rem; height: 0.3rem; line-height: 0.3rem">
          在场时间：{{ adverInfo ? adverInfo.hour + '时' : ''
          }}{{ adverInfo ? adverInfo.min + '分' : ''
          }}{{ adverInfo ? adverInfo.seconds + '秒' : '' }}
        </div>
        <div
          style="
            font-size: 0.14rem;
            height: 0.3rem;
            line-height: 0.3rem;
            border-bottom: 1px dashed #f1f1f1;
            padding-bottom: 0.03rem;
          "
        >
          优惠券：<span style="margin-left: 13px">{{
            adverInfo ? adverInfo.couponPrice1 : ''
          }}</span>
        </div>
        <div
          style="
            width: 100%;
            margin: 0 auto;
            height: 0.46rem;
            line-height: 0.46rem;
            border-bottom: 1px dashed #f1f1f1;
          "
        >
          <span
            style="
              font-size: 0.14rem;
              color: #999;
              position: relative;
              top: -0.05rem;
            "
            >支付金额</span
          >
          <span style="float: right">
            {{
              adverInfo && adverInfo.actually_paid ? (Number(adverInfo.actually_paid) / 100).toFixed(2) : ''
            }}</span
          ><span
            style="
              font-size: 0.12rem;
              float: right;
              position: relative;
              top: 0.04rem;
              left: -0.02rem;
            "
            >￥</span
          >
        </div>
        <div
          style="
            width: 100%;
            margin-top: 0.15rem;
            height: 0.46rem;
            line-height: 0.46rem;
            font-size: 0.14rem;
            color: #1789f9;
            text-align: right;
          "
        >
          <span  @click="fp_btn"
            >开具电子发票
            <van-icon
              style="position: relative; top: 0rem; font-size: 0.16rem"
              name="arrow"
            />
          </span>
        </div>
      </div>
    </div>
    <div style="width: 85%; position: relative; top: 0px; left: 7.5%">
      <div class="tishi-btn-inner">
        <van-button
          type="info"
          style="
            color: #fff;
            background: #1789f9;
            border: none;
            font-size: 0.2rem;
          "
          size="large"
          @click="pnulScanInner"
          >完成</van-button
        >
      </div>
    </div>
    <div class="banner" v-if="!isCloseAD">
      <adver urls="501052502699999232"></adver>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Adver from '../../components/Adver'
import * as common from '../../api/common'
import * as constant from '../../api/constant'
import * as util from '../../api/util'
import { mapState } from 'vuex'
export default {
  name: 'advertising',
  components: {
    Adver
  },
  data () {
    return {
      isPress: false,
      adver_info: '',
      isCloseAD: false
    }
  },
  computed: {
    ...mapState({
      sid: state => state.sid.sid,
      plateNumber: state => state.plateNumber.plateNumber,
      adverInfo: state => state.adverInfo.adverInfo

    })
  },
  watch: {
    adverInfo (newVal) {
      // this.adver_info = this.adverInfo
      // this.adver_info.actually_paid = 99
    }
  },
  methods: {
    // 获取关闭广告配置
    queryADConfig () {
      const flag = util.judgeADClose({}, 2, 2)
      this.isCloseAD = flag
      console.log('完成', this.isCloseAD)
    },
    pnulScanInner () {
      window.location.replace('https://pa03.yuntingiot.com/api/ac/477786592149311488')
    },
    fp_btn () {
      if (this.isPress) {
        return
      }
      const that = this
      const sid = that.sid
      const vhPlateNumber = that.adverInfo.plateNumber
      const parkName = that.adverInfo.parkName
      const feeOrderSn = that.$route.query.parkcloud_order_sn
      const actuallyPaid = that.adverInfo.actually_paid
      // const sid = 'yuen03'
      // const vhPlateNumber = '浙AP2002'
      // const parkName = ''
      // const feeOrderSn = '3e3dasds'
      // const actuallyPaid = 1

      that.isPress = true
      axios({
        url: common.cloudPayUrl + '/pub/pay.bill.check_channel',
        method: 'POST',
        data: {
          sid: sid
        },
        header: {
          'content-type': 'application/json' // 默认值
        }
      }).then(res => {
        console.log('res', res)
        if (res.data.code === common.resCode.OK) {
          const applyBillInfo = {
            sid: sid,
            vh_p_number: vhPlateNumber,
            parkName: parkName,
            fee_order_sn: feeOrderSn,
            actually_paid: actuallyPaid
          }
          this.$store.commit(constant.keyApplyBillInfoVuex, applyBillInfo)
          this.$router.push({
            name: 'invoiceMessage',
            params: { type: false, num: actuallyPaid, applyDirec: 0 }
          })
        } else {
          if (res.data.msg) {
            this.$toast.fail(res.data.msg)
          } else {
            this.$toast.fail('加载失败')
          }
          this.isPress = false
        }
      }).catch((e) => {
        console.log(e)
        this.$toast.fail('加载失败')
        this.isPress = false
      })
    },

    filterTime (timeStr) {
      if (!timeStr) return ''
      return common.format(timeStr, 'yyyy-MM-dd hh:mm:ss')
    }
  },
  mounted () {
    this.queryADConfig()
    // this.adver_info = JSON.parse(this.$Storejs.getItem('adver_info'))
    // this.$Storejs.removeItem('vh_p_number')
  },
  filters: {

  },
  created () {

  }
}
</script>

<style scoped>
.main {
  width: 100%;
  height: 100vh;
  position: relative;
  font-size: 0.24rem;
}

.top_bg {
  width: 100%;
  height: 1.36rem;
  background: -webkit-linear-gradient(180deg, #3f87f1, #5aaef5);
}

.top_bg img {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
}

.top_bg .zf_suess {
  width: 100%;
  height: 0.475rem;
  position: absolute;
  top: 0.21rem;
}

.top_bg .zf_suess ul {
  width: 100%;
  height: 0.55rem;
}

.top_bg .zf_suess ul li {
  float: left;
  height: 0.55rem;
}

.top_bg .zf_suess ul li img {
  width: 0.55rem;
  float: right;
}

.juan {
  width: 90%;
  position: relative;
  top: -34px;
  left: 5%;
}

.juan img {
  width: 100%;
  position: relative;
  top: 0;
}

</style>
